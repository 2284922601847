<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">hello</div>
    </header>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import {
  apiBaseUrl,
  admin_key,
  company_wait_prefix,
  liveBaseUrl,
} from "../../constants/config";
import { mapActions } from "vuex";
import { janusUrl, iceServer } from "../../constants/config";
import Room from "janus-room";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  data() {
    return {
      speakers: [],
      visitors: [],
      checkTimer: null,
      event: null,
      event_id: null,
      content_type_id: null,
      show_modal: false,
      connection: null,
      devices: [],
      roomUrl: "",
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["signOut"]),
  },
  components: {},
  computed: {},
  watch: {},
};
</script>

